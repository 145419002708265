.rest-card {
    cursor: pointer;
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075)!important;
    transition: 0.25s;
}

.rest-card:hover {
    box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, .350)!important;
}

.rest-card img {
    user-drag: none; 
    user-select: none;
    -webkit-user-drag: none; 
    -webkit-user-select: none;
}

.rest-card img.card-img-top {
    width: 100%;
    height: 50vh;
    object-fit: cover;
}
